import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import padStart from 'lodash/padStart';
import padEnd from 'lodash/padEnd';
import head from 'lodash/head';
import tail from 'lodash/tail';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import pad from 'lodash/pad';

import { LOG_TYPE, logger, toQueryString } from '@emobg/web-utils';

export const phoneNumber = phone => {
  if (!isString(phone)) {
    throw new Error('phone should be a string');
  }

  return phone.replace(/ /g, '');
};

/**
 * It wraps the string 'value' with the characters given 'encloserCharacter'
 * If 'encloserCharacter' is an Array it would wrap at the beginning the first item and at the end the second
 * @param {string} value
 * @param {string|Array} encloserCharacter
 * @example <caption>Example usage of wrapString.</caption>
 * // returns "_stringed-value_"
 * wrapString('stringed-value', '_')
 * @example <caption>Example usage of wrapString with multiple encloser.</caption>
 * // returns "(stringed-value)"
 * wrapString('stringed-value', ['(', ')'])
 * @returns {string}
 */
export const wrapString = (value, encloserCharacter) => {
  if (isEmpty(value)) {
    const message = 'Missing mandatory argument: value';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }
  if (isEmpty(encloserCharacter)) {
    const message = 'Missing mandatory argument: encloserCharacter';
    logger.message(message, LOG_TYPE.error);
    throw new Error(message);
  }
  if (isArray(encloserCharacter)) {
    if (size(encloserCharacter) !== 2) {
      const message = 'Argument encloserCharacter bad format: If argument is an array, it must contains two strings';
      logger.message(message, LOG_TYPE.error);
      throw new Error(message);
    }
    return padEnd(
      padStart(value, size(value) + 1, head(encloserCharacter)),
      size(value) + 2,
      tail(encloserCharacter),
    );
  }
  return pad(value, size(value) + 2, encloserCharacter);
};

/**
 * It's append route given with params given as query string
 *
 * @param {object} router
 * @param {string} routeName
 * @param {object} params
 * @example <caption>Example usage of composeLegalDocumentLink.</caption>
 *
 * @example <caption>Example usage of composeExternalLinkWithParam with params.</caption>
 * // returns "ubeeqo.com/legal?userUuid=1234"
 * composeExternalLinkWithParam($router, 'legal.legal-documents', { userUuid: '1234' })
 * @returns {string}
 */
export const composeExternalLinkWithParam = ({ router, routeName, params }) => {
  const url = router.resolve({
    name: routeName,
  }).href;
  const query = toQueryString({ ...params });

  return `${url}${query}`;
};
