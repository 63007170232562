export const ACTIVATE_DEACTIVATE_EMPLOYEE = 'activate_deactivate_employees';
export const ACTIVE_DEACTIVE_FLEET_VEHICLE = 'activate_deactivate_fleet_vehicles';
export const ADD_PAYMENT_METHOD = 'add_payment_method';
export const CANCEL_CS_LD_BOOKING_ON_BEHALF_EMPLOYEES = 'cancel_cs_ld_booking_on_behalf_of_other_employees';
export const CANCEL_CS_LD_OWN_BOOKINGS = 'cancel_cs_ld_own_bookings';
export const CREATE_BOOKING_ON_BEHALF_EMPLOYEES = 'create_booking_on_behalf_of_other_employees';
export const CREATE_CS_LD_OWN_BOOKINGS = 'create_cs_ld_own_bookings';
export const EDIT_BOOKING_ON_BEHALF_EMPLOYEES = 'edit_booking_on_behalf_of_other_employees';
export const EDIT_COMPANY_SETTINGS = 'edit_company_settings';
export const EDIT_OWN_CS_BOOKINGS = 'edit_own_cs_bookings';
export const EDIT_PAYMENT_METHOD = 'edit_payment_method';
export const EDIT_USER_ROLE = 'edit_user_role';
export const EXPORT_COMPANY_INVOICES = 'export_company_invoices';
export const EXPORT_COMPANY_REPORTS = 'export_company_reports';
export const INTERVENTION_BOOKING_TYPE = 'intervention_booking_type';
export const INVITE_EMPLOYEES = 'invite_employees';
export const PAY_PENDING_INVOICES = 'pay_pending_invoices';
export const SELECT_COMPANY_TARIFF = 'select_company_tariff';
export const REGULAR_BOOKING_TYPE = 'regular_booking_type';
export const UNLOCK_VEHICLE_OWN_BOOKINGS = 'unlock_vehicle_own_booking';
export const VIEW_BOOKINGS_COMPANY_EMPLOYEES = 'view_bookings_of_company_employees';
export const VIEW_COMPANY_INVOICES = 'view_company_invoices';
export const VIEW_COMPANY_REPORTS = 'view_company_reports';
export const VIEW_COMPANY_SETTINGS = 'view_company_settings';
export const VIEW_COMPANY_TARIFF = 'view_company_tariff';
export const VIEW_EMPLOYEES = 'view_employees';
export const VIEW_FLEET_VEHICLES = 'view_fleet_vehicles';
export const VIEW_INTERVENTION_BOOKINGS_COMPANY_EMPLOYEES = 'view_intervention_bookings_of_company_employees';
export const VIEW_OWN_CS_BOOKINGS = 'view_own_cs_bookings';
export const VIEW_PAYMENT_METHODS = 'view_payment_methods';
export const ACCESS_TO_BACKOFFICE = 'access_to_backoffice';
export const EDIT_PERSONAL_SETTINGS = 'edit_personal_settings';
export const VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS = 'view_own_behalf_employee_bookings';
export const EDIT_OWN_BEHALF_EMPLOYEE_BOOKINGS = 'edit_own_behalf_employee_bookings';

export const REQUIRED_PERMISSION_COMPANY_ADMIN = [
  [VIEW_COMPANY_REPORTS],
  [VIEW_FLEET_VEHICLES],
  [VIEW_COMPANY_INVOICES],
  [VIEW_PAYMENT_METHODS],
  [VIEW_COMPANY_SETTINGS],
  [VIEW_BOOKINGS_COMPANY_EMPLOYEES],
];

export const REQUIRED_PERMISSION_COMPANY_ADMIN_CIRCLES = [
  [VIEW_EMPLOYEES],
];
