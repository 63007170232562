<script>
import snakeCase from 'lodash/snakeCase';

export default {
  name: 'NavbarLink',

  props: {
    testId: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      required: true,
    },
  },

  computed: {
    dataTestId() {
      return `${snakeCase(this.testId)}-sidebar-link`;
    },
    navbarLinkAnchor() {
      return this.link.useNativeTag
        ? this.$refs.navbarLinkAnchor
        : this.$refs.navbarLinkAnchor.$el;
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <a
      v-if="link.useNativeTag"
      ref="navbarLinkAnchor"
      :href="link.href"
      :target="link.target"
      :data-test-id="dataTestId"
      class="phNavbar_link w-100"
    >
      <span :class="{'position-relative d-block': link.badge}">
        {{ link.label }}
        <ui-icon
          v-if="link.icon"
          :icon="link.icon"
          :size="ICONS_SIZES.small"
          class="ml-2"
        />
        <ui-badge
          v-if="link.badge"
          :color="COLORS.primary"
        >
          {{ link.badge }}
        </ui-badge>
      </span>
    </a>
    <RouterLink
      v-else
      ref="navbarLinkAnchor"
      :exact="link.exact"
      :to="link.to"
      :target="link.target"
      :data-test-id="dataTestId"
      class="phNavbar_link w-100"
    >
      <span
        :class="{
          'position-relative d-block': link.badge
        }"
      >
        {{ link.label }}
        <ui-icon
          v-if="link.icon"
          :icon="link.icon"
          :size="ICONS_SIZES.small"
          class="ml-2"
        />
        <ui-badge
          v-if="link.badge"
          :color="COLORS.primary"
        >
          {{ link.badge }}
        </ui-badge>
      </span>
    </RouterLink>
  </div>
</template>
