<template>
  <ui-sidebar
    :open="isOpen"
    dismissible
    right
    data-test-id="right_navigation-panel"
    @close="$emit('on:close')"
  >
    <div class="SideBar d-flex flex-column w-100 h-100">
      <div
        class="SideBar__Header__Container emobg-border-bottom-1 emobg-border-color-ground-light d-flex flex-column emobg-background-color-white"
        data-test-id="header-container"
      >
        <div class="SideBar__Header header pt-4 d-flex flex-row-reverse position-sticky w-100 z-index-1">
          <ui-button
            :face="FACES.text"
            pill
            class="ml-auto position-absolute"
            @clickbutton="$emit('on:close')"
          >
            <ui-icon
              :icon="ICONS.cross"
              :size="SIZES.small"
              :color="GRAYSCALE.black"
            />
          </ui-button>
          <div class="SideBar__Header__TopSection w-100">
            <div class="d-flex px-4 pt-3 flex-column">
              <span
                class="SideBar__Header__TopSection__UserInfo emobg-font-x-large emobg-font-weight-bold"
                data-test-id="full_name-text"
              >
                {{ userFullName }}
              </span>
              <ui-dropdown
                v-if="personalProfile && hasBusinessProfile"
                :color="COLORS.primary"
                :size="SIZES.large"
                :placement="PLACEMENTS.bottomStart"
                :text-color="GRAYSCALE.white"
                class="my-3 w-100"
                @toggledropdown="({ detail }) => isDropdownOpen = detail"
              >
                <div
                  slot="trigger"
                  class="px-3 mr-auto cursor-pointer w-100 d-flex align-items-center"
                >
                  <ui-icon
                    :icon="ICONS[getCurrentProfileType]"
                    class="mr-2"
                  />
                  <span class="mr-1 mt-1">
                    {{ $t(`header.profile_${getCurrentProfileType}`) }}
                  </span>
                  <div class="d-flex ml-auto">
                    <ui-button
                      :face="FACES.text"
                      :text-color="GRAYSCALE.white"
                      square
                    >
                      <ui-icon
                        :icon="isDropdownOpen ? ICONS.arrowUp : ICONS.arrowDown"
                        :size="ICONS_SIZES.small"
                        :color="GRAYSCALE.white"
                        data-test-id="dropdown_toggle-icon"
                      />
                    </ui-button>
                  </div>
                </div>
                <div slot="content">
                  <div class="d-flex flex-column">
                    <span
                      v-for="({ label, icon, uuid }) in profileOptions"
                      :key="uuid"
                      class="SideBar__Section__Subsection d-flex p-3 align-items-baseline
                      cursor-pointer emobg-background-color-ground-lighter-hover"
                      @click="selectProfile(uuid)"
                    >
                      <ui-icon
                        :icon="icon"
                        class="mr-2"
                      />
                      <p class="mr-6">
                        {{ label }}
                      </p>
                    </span>
                  </div>
                </div>
              </ui-dropdown>
            </div>
          </div>
        </div>
        <div
          class="SideBar__Section__Subsection d-flex w-100 my-2 py-2 pl-4 cursor-pointer"
          @click="goToAccountSettings"
        >
          <ui-icon
            :icon="ICONS.personal"
            class="mr-2"
          />
          <span>
            {{ $t('SideBar.sections.my_account.title') }}
          </span>
        </div>
      </div>
      <div
        :ref="SCROLLABLE_SECTION_REF"
        class="SideBar__Scrollable__Section h-100 d-flex flex-column overflow-auto emobg-background-color-white"
      >
        <div class="SideBar__Body body emobg-border-bottom-1 emobg-border-color-ground-light">
          <div
            v-for="(menuSection, index) in visibleSections"
            :key="`menuSection-${index}`"
            class="SideBar__Section my-3"
          >
            <div
              v-if="menuSection.title"
              class="emobg-font-large emobg-font-weight-bold px-4"
              :data-test-id="`${menuSection.title}_section-text`"
            >
              {{ menuSection.title }}
            </div>
            <div
              v-for="subSection in menuSection.subsections"
              :key="subSection.title"
              class="SideBar__Section__Subsection emobg-background-color-ground-lighter-hover
              cursor-pointer px-4 py-2 d-flex mt-2 align-items-center"
              @click="navigateSection(subSection)"
            >
              <ui-icon
                :icon="subSection.icon"
                class="mr-3"
              />
              <span class="mr-auto">
                {{ subSection.title }}
              </span>
              <ui-icon
                v-if="subSection.external || subSection.target"
                :icon="ICONS.externalUrl"
                class="ml-auto"
              />
            </div>
            <hr
              v-show="index < size(visibleSections) - 1"
              class="mx-4"
            >
          </div>
        </div>
        <div
          v-if="isBusinessProfileActive"
          class="SideBar__Footer text-center d-flex flex-column py-4 px-4 justify-content-end emobg-background-color-ground-lightest"
        >
          <div class="mb-4 align-items-center d-flex flex-column">
            <div
              class="emobg-font-medium emobg-font-weight-bold mb-2"
              v-html="$t('main_menu.contact.asistance')"
            />
            <div
              class="emobg-font-small mb-2"
              v-html="$t('main_menu.contact.customer_care', {
                workingHours: get(getCustomerServiceOperator, 'customer_service_opening_hours'),
              })"
            />
            <div class="d-flex">
              <ui-icon
                :icon="OS_ICONS.phone"
                :color="COLORS.primary"
              />
              <div class="emobg-color-primary emobg-font-weight-bold text-underline ml-2">
                {{ customerServicePhoneNumberFormatted }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-sidebar>
</template>

<script>
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import invoke from 'lodash/invoke';
import pick from 'lodash/pick';
import get from 'lodash/get';
import join from 'lodash/join';
import isString from 'lodash/isString';
import set from 'lodash/set';
import { ref } from 'vue';

import { ICONS } from '@emobg/web-components';
import { aclService } from '@emobg/access-utils';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
  DELAY, navigationErrorHandler, snakeCaseKeys, toQueryString,
} from '@emobg/web-utils';
import { show } from '@intercom/messenger-js-sdk';

import {
  getCurrentProfileType,
  getProfileByUUID,
  hasBusinessProfile,
  isBusinessProfileActive,
  isPersonalProfileActive,
  personalProfile,
  profileCollection,
  setCurrentProfile,
  selectProfile as triggerSelectProfile,
} from '@/stores/User/Profile/ProfileMapper';
import {
  allowsPersonalUseOfOpen,
  canChangeBusinessProfilePaymentMethod,
  canChangeCostAllocation,
  circleManagerPermissions,
  companyAllowsCarpooling,
  dedicatedFleetCSOperatorUuid,
  doesCompanyOwnsData,
  hasDedicatedFleet,
  isActivatedUser,
  profilePermissions,
  trackingId,
  userFullName,
  userUuid,
} from '@/stores/User/UserData/UserDataMapper';
import { employeePolicy } from '@/stores/Company/CompanyMapper';

import {
  findCSOperatorByUUID,
  getVisitedCsOperator,
  getVisitedCsOperatorHelpLink,
} from '@/stores/CSOperator/CSOperatorMapper';

import { isBusinessProfile } from '@/helpers/user/profiles';
import accountSettingsRoutesNames from '@Account/Settings/router/routes-names';

import { composeSections, evaluateSections } from '@/components/Navbar/SideBar/helpers/sidebarHelpers';
import { isChatVisibleOnRoute, updateChatBotParams } from '@/utils/Chabot/chatbot.utils';

export default {
  name: 'Sidebar',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const isDropdownOpen = ref(false);
    const hasVirtualAssistantSection = ref(false);
    return {
      isDropdownOpen,
      hasVirtualAssistantSection,
    };
  },

  computed: {
    allowsPersonalUseOfOpen,
    profileCollection,
    isBusinessProfileActive,
    isPersonalProfileActive,
    getCurrentProfileType,
    personalProfile,
    hasBusinessProfile,
    getVisitedCsOperatorHelpLink,
    doesCompanyOwnsData,
    getProfileByUUID,
    circleManagerPermissions,
    profilePermissions,
    userFullName,
    userUuid,
    dedicatedFleetCSOperatorUuid,
    findCSOperatorByUUID,
    getVisitedCsOperator,
    trackingId,
    companyAllowsCarpooling,
    employeePolicy,
    hasDedicatedFleet,
    canChangeBusinessProfilePaymentMethod,
    canChangeCostAllocation,
    isActivatedUser,
    getCustomerServiceOperator() {
      return this.dedicatedFleetCSOperatorUuid
        ? this.findCSOperatorByUUID(this.dedicatedFleetCSOperatorUuid)
        : this.getVisitedCsOperator;
    },

    profileOptions() {
      return map(this.profileCollection, profile => ({
        ...pick(profile, ['uuid', 'type']),
        label: profile.type ? this.$t(`header.profile_${profile.type}`) : '',
        icon: ICONS[profile.type],
      }));
    },
    visibleSections() {
      const sections = composeSections({
        router: this.$router,
        isBusinessProfileActive: this.isBusinessProfileActive,
        isPersonalProfileActive: this.isPersonalProfileActive,
        companyAllowsCarpooling: this.companyAllowsCarpooling,
        hasLogBook: this.hasLogBook,
        getVisitedCsOperatorHelpLink: this.getVisitedCsOperatorHelpLink,
        doesCompanyOwnsData: this.doesCompanyOwnsData,
        openConsentChoices: this.openConsentChoices,
        hasChatBot: this.hasVirtualAssistantSection,
        openChatBot: () => {
          show();
        },
        trackingId: this.trackingId,
        employeePolicy: this.employeePolicy,
        hasDedicatedFleet: this.hasDedicatedFleet,
        canChangeBusinessProfilePaymentMethod: this.canChangeBusinessProfilePaymentMethod,
        canChangeCostAllocation: this.canChangeCostAllocation,
        isActivatedUser: this.isActivatedUser,
        userUuid: this.userUuid,
      });

      return evaluateSections({ sections });
    },
    customerServicePhoneNumberFormatted() {
      const customerServicePhone = get(this.getCustomerServiceOperator, 'customer_service_phone');
      const serviceCountry = get(this.getCustomerServiceOperator, 'country_code');

      const phoneNumberObject = !isNil(customerServicePhone)
        && !isNil(serviceCountry)
        && isValidPhoneNumber(customerServicePhone, serviceCountry)
        ? parsePhoneNumber(customerServicePhone, serviceCountry)
        : customerServicePhone;

      const phoneNumber = get(phoneNumberObject, 'nationalNumber');

      const numberChunked = isString(phoneNumber) ? phoneNumber.match(/.{1,3}/g) : '';
      const numberFormatted = join(numberChunked, ' ');

      return `+${get(phoneNumberObject, 'countryCallingCode')} ${numberFormatted}`;
    },
  },

  watch: {
    isOpen(isSidebarOpen) {
      this.isDropdownOpen = false;
      this.hasVirtualAssistantSection = !!(window && window.intercomSettings);

      if (isChatVisibleOnRoute(this.$route.name)) {
        updateChatBotParams({
          hide_default_launcher: isSidebarOpen,
        });
      }

      if (!isSidebarOpen) {
        setTimeout(() => set(get(this, `$refs.${this.SCROLLABLE_SECTION_REF}`), 'scrollTop', 0), DELAY.medium);
      }
    },
  },
  created() {
    this.routes = accountSettingsRoutesNames;
    this.SCROLLABLE_SECTION_REF = 'scrollableSection';
  },
  methods: {
    get,
    size,
    setCurrentProfile,
    triggerSelectProfile,
    openConsentChoices() {
      if (!isNil(window.Didomi)) {
        window.Didomi.preferences.show();
      }
    },

    selectProfile(profileUuid) {
      const profileSelected = this.getProfileByUUID(profileUuid);
      if (profileSelected.type === this.getCurrentProfileType) {
        return;
      }

      this.$cookie.set(`last_account_used_${this.userUuid}`, profileSelected.uuid, '1M');
      const userProfilePermissions = this.profilePermissions(profileSelected.uuid);
      aclService.setUserPermissions(userProfilePermissions);

      const circlePermissions = isBusinessProfile(profileSelected.type)
        ? this.circleManagerPermissions
        : [];
      aclService.setCirclesPermissions(circlePermissions);

      this.setCurrentProfile(profileSelected);

      this.triggerSelectProfile(profileSelected.uuid);
    },

    goToAccountSettings() {
      if (this.isDropdownOpen) {
        return;
      }

      this.$router.push({ name: this.routes.accountSettings }).catch(navigationErrorHandler);
    },

    navigateSection(section) {
      if (this.isDropdownOpen) {
        return;
      }

      if (section.external) {
        let url = section.route;

        if (section.query) {
          const queryString = toQueryString(snakeCaseKeys(section.query));
          url = `${section.route}${queryString}`;
        }

        window.open(url, '_blank');
      } else if (section.target) {
        const resolvedRoute = this.$router.resolve({ name: section.route, query: section.query });
        window.open(resolvedRoute.href, section.target);
      } else if (section.fn) {
        invoke(section, 'fn');
      } else {
        this.$router.push({ name: section.route, query: section.query }).catch(navigationErrorHandler);
      }
      this.$emit('on:close');
    },
  },
};
</script>
