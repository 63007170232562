// sidebarhelpers
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import take from 'lodash/take';

import { COLORS, ICONS } from '@emobg/web-components';
import { aclService } from '@emobg/access-utils';

import { useTranslations } from '@/composable/App/Translations';

import {
  VIEW_BOOKINGS_COMPANY_EMPLOYEES,
  VIEW_COMPANY_INVOICES,
  VIEW_COMPANY_REPORTS,
  VIEW_EMPLOYEES,
  VIEW_FLEET_VEHICLES,
  VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS,
} from '@/constants/permissions';

import PersonalProfileRoutesNames from '@Profile/Personal/router/routes-names';
import BusinessProfileRoutesNames from '@Profile/Business/router/routes-names';
import InviteAFriendRoutesNames from '@Profile/InviteFriend/router/routes-names';
import BookingRoutesNames from '@Bookings/router/routes-names';
import SharedRoutesNames from '@Shared/router/routes-names';
import { NotificationCenterRoute } from '@Shared/router/index';

import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import accountSettingsRoutesNames from '@Account/Settings/router/routes-names';
import LegalDocumentsRoutesNames from '@/domains/Document/Legal/router/routes-names';
import { composeExternalLinkWithParam } from '@/utils/normalize';

const { $t } = useTranslations();

const LIMIT_BY_SUBSECTION = 5;

/**
 * It compose an array of objects according to the data passed to compose the sections
 * @param {object} router
 * @param {boolean} isBusinessProfileActive
 * @param {boolean} isPersonalProfileActive
 * @param {boolean} companyAllowsCarpooling
 * @param {boolean} hasLogBook
 * @param {string} getVisitedCsOperatorHelpLink
 * @param {boolean} doesCompanyOwnsData
 * @param {function} openConsentChoices
 * @param {function} openChatBot
 * @param {string} trackingId
 * @param {boolean} employeePolicy
 * @param {boolean} hasChatBot
 * @param {boolean} hasDedicatedFleet
 * @param {boolean} canChangeBusinessProfilePaymentMethod
 * @param {boolean} canChangeCostAllocation
 * @param {boolean} isActivatedUser
 * @param {string} userUuid
 * @example <caption>Example usage of composeSections.</caption>
* // returns '
 * //  [
 * //   title: 'title_section_1',
 * //   subsections: [
 * //     {
 * //       title: 'subtitle_section_1',
 * //       condition: true,
 * //       icon: 'icon_1',
 * //       route: 'route',
 * //       weight: 0
 * //     },
 * //     {
 * //       title: 'subtitle_section_2',
 * //       condition: false,
 * //       icon: 'icon_2',
 * //       route: 'route_2',
 * //       weight: 1
 * //     }
 * //   ],
 * //   title: 'title_section_1',
 * //   subsections: [
 * //     {
 * //       title: 'subtitle_section_3',
 * //       condition: true,
 * //       icon: 'icon_3',
 * //       route: 'route',
 * //       weight: 0
 * //     },
 * //     {
 * //       title: 'subtitle_section_4',
 * //       condition: false,
 * //       icon: 'icon_4',
 * //       route: 'route_2',
 * //       weight: 1
 * //     }
 * //   ]
 * //  ]
 * composeSections({ isBusinessProfileActive: true, isPersonalProfileActive: false, companyAllowsCarpooling: true ... })
 *
 * @return {array}
 */
export const composeSections = ({
  router,
  isBusinessProfileActive,
  isPersonalProfileActive,
  companyAllowsCarpooling,
  hasLogBook,
  getVisitedCsOperatorHelpLink,
  doesCompanyOwnsData,
  openConsentChoices,
  openChatBot,
  hasChatBot,
  trackingId,
  employeePolicy,
  hasDedicatedFleet,
  canChangeBusinessProfilePaymentMethod,
  canChangeCostAllocation,
  isActivatedUser,
  userUuid,
}) => [
  {
    title: $t('SideBar.sections.trips.title'),
    subsections: [
      {
        title: $t('SideBar.sections.trips.subsections.bookings.title'),
        condition: true,
        icon: ICONS.carsharing,
        route: BookingRoutesNames.myBookings,
        weight: 0,
      },
      {
        title: $t('SideBar.sections.trips.subsections.logbooks.title'),
        condition: isBusinessProfileActive && hasLogBook,
        icon: ICONS.logbook,
        route: BookingRoutesNames.myBookingsLogbook,
        weight: 1,
      },
      {
        title: $t('SideBar.sections.trips.subsections.carpooling.title'),
        condition: isBusinessProfileActive && companyAllowsCarpooling,
        icon: ICONS.carPooling,
        route: BookingRoutesNames.carpooling,
        weight: 2,
      },
      {
        title: $t('SideBar.sections.trips.subsections.coverage.title'),
        condition: isPersonalProfileActive && isActivatedUser,
        icon: ICONS.insuranceEmpty,
        route: PersonalProfileRoutesNames.insurances,
        weight: 3,
      },
      {
        title: $t('SideBar.sections.trips.subsections.invoices.title'),
        condition: isPersonalProfileActive,
        icon: ICONS.invoicing,
        route: PersonalProfileRoutesNames.invoices,
        weight: 4,
      },
      {
        title: $t('SideBar.sections.trips.subsections.pricing_plans.title'),
        condition: isPersonalProfileActive,
        icon: ICONS.pricingPlan,
        route: PersonalProfileRoutesNames.tariffs,
        weight: 5,
      },
      {
        title: $t('SideBar.sections.trips.subsections.payment_methods.title'),
        condition: isPersonalProfileActive || canChangeBusinessProfilePaymentMethod,
        icon: ICONS.cards.emptyPlainCard,
        route: isPersonalProfileActive
          ? accountSettingsRoutesNames.paymentMethods
          : BusinessProfileRoutesNames.paymentMethods,
        weight: 6,
      },
      {
        title: $t('SideBar.sections.trips.subsections.cost_allocations.title'),
        condition: isBusinessProfileActive && canChangeCostAllocation,
        icon: ICONS.document,
        route: BusinessProfileRoutesNames.costAllocations,
        weight: 7,
      },
    ],
  },
  {
    title: $t('SideBar.sections.company_administration.title'),
    subsections: [
      {
        title: $t('SideBar.sections.company_administration.subsections.employee_bookings.title'),
        condition: isBusinessProfileActive && (aclService.hasPermissions(VIEW_BOOKINGS_COMPANY_EMPLOYEES)
            || aclService.hasPermissions(VIEW_OWN_BEHALF_EMPLOYEE_BOOKINGS)),
        icon: ICONS.company,
        route: CompanyAdminRoutesNames.employeeBookingsList,
        weight: 0,
      },
      {
        title: $t('SideBar.sections.company_administration.subsections.employees_list.title'),
        condition: isBusinessProfileActive && aclService.hasPermissions(VIEW_EMPLOYEES),
        icon: ICONS.users,
        route: CompanyAdminRoutesNames.employeeList,
        weight: 1,
      },
      {
        title: $t('SideBar.sections.company_administration.subsections.company_invoices.title'),
        condition: isBusinessProfileActive && aclService.hasPermissions(VIEW_COMPANY_INVOICES),
        icon: ICONS.invoicing,
        route: CompanyAdminRoutesNames.invoices,
        weight: 2,
      },
      {
        title: $t('SideBar.sections.company_administration.subsections.fleet.title'),
        condition: isBusinessProfileActive && aclService.hasPermissions(VIEW_FLEET_VEHICLES) && hasDedicatedFleet,
        icon: ICONS.fancyCar,
        route: CompanyAdminRoutesNames.fleetList,
        weight: 3,
      },
      {
        title: $t('SideBar.sections.company_administration.subsections.booking_overview.title'),
        condition: isBusinessProfileActive && aclService.hasUserPermissions(VIEW_BOOKINGS_COMPANY_EMPLOYEES) && hasDedicatedFleet,
        icon: ICONS.calendar,
        route: CompanyAdminRoutesNames.bookingOverview,
        weight: 4,
      },
      {
        title: $t('SideBar.sections.company_administration.subsections.reporting.title'),
        condition: isBusinessProfileActive && aclService.hasPermissions(VIEW_COMPANY_REPORTS) && hasDedicatedFleet,
        icon: ICONS.data,
        route: CompanyAdminRoutesNames.reporting,
        weight: 5,
      },
    ],
  },
  {
    title: $t('SideBar.sections.promotions.title'),
    subsections: [
      {
        title: $t('SideBar.sections.promotions.subsections.credit.title'),
        condition: isPersonalProfileActive,
        icon: ICONS.credits,
        route: PersonalProfileRoutesNames.promotions,
        weight: 0,
      },
      {
        title: $t('SideBar.sections.promotions.subsections.refer_a_friend.title'),
        condition: isPersonalProfileActive,
        icon: ICONS.invitation,
        route: InviteAFriendRoutesNames.inviteFriendView,
        weight: 1,
      },
    ],
  },
  {
    title: $t('SideBar.sections.support.title'),
    subsections: [
      {
        title: $t('SideBar.sections.support.subsections.virtual_assistant.title'),
        condition: hasChatBot,
        icon: ICONS.help,
        fn: openChatBot,
        weight: 0,
      },
      {
        title: $t('SideBar.sections.support.subsections.help.title'),
        condition: true,
        icon: ICONS.info,
        route: getVisitedCsOperatorHelpLink,
        external: true,
        weight: 0,
      },
      {
        title: $t('SideBar.sections.support.subsections.communication_preferences.title'),
        condition: !doesCompanyOwnsData,
        icon: ICONS.alarm,
        route: NotificationCenterRoute.path,
        query: {
          tracking_id: trackingId,
        },
        external: true,
        weight: 1,
      },
    ],
  },
  {
    title: $t('SideBar.sections.legal.title'),
    subsections: [
      {
        title: $t('SideBar.sections.legal.subsections.help.title'),
        condition: true,
        icon: ICONS.document,
        route: composeExternalLinkWithParam({
          router,
          routeName: LegalDocumentsRoutesNames.legalDocuments,
          params: { userUuid },
        }),
        external: true,
        weight: 0,
      },
      {
        title: $t('main_menu.links.employeePolicy'),
        condition: employeePolicy,
        icon: ICONS.document,
        route: BusinessProfileRoutesNames.employeePolicy,
        external: false,
        target: '_blank',
        weight: 1,
      },
      {
        title: $t('SideBar.sections.legal.subsections.cookie_preferences.title'),
        condition: true,
        icon: ICONS.cookies,
        fn: openConsentChoices,
        weight: 2,
      },
    ],
  },
  {
    subsections: [
      {
        title: $t('SideBar.sections.logout.title'),
        condition: true,
        color: COLORS.danger,
        icon: ICONS.logout,
        route: SharedRoutesNames.logout,
        weight: 0,
      },
    ],
  },
];

/**
 * It evaluates the conditions for the sections, sort and sanitize the result to render directly
 * @param {array} evaluateSections
 * @example <caption>Example usage of evaluateSections.</caption>
* // returns '
 * //  [
 * //   title: 'title_section_1',
 * //   subsections: [
 * //     {
 * //       title: 'subtitle_section_1',
 * //       condition: true,
 * //       icon: 'icon_1',
 * //       route: 'route',
 * //       weight: 0
 * //     },
 * //   ],
 * //   title: 'title_section_1',
 * //   subsections: [
 * //     {
 * //       title: 'subtitle_section_3',
 * //       condition: true,
 * //       icon: 'icon_3',
 * //       route: 'route',
 * //       weight: 0
 * //     },
 * //   ]
 * //  ]
 * evaluateSections({ [ title: 'title_section_1', subsections: [{ title: 'subtitle_section_1', condition: true .. }, { title: 'subtitle_section_2', condition: false, weight: 1 } ], ... ]})
 *
 * @return {array}
 */
export const evaluateSections = ({ sections }) => filter(
  map(sections, section => ({
    ...section,
    subsections: take(
      sortBy(filter(section.subsections, 'condition'), 'weight'),
      LIMIT_BY_SUBSECTION,
    ),
  })),
  section => size(section.subsections),
);
